<template>
  <app-modal-invoice-pdf
    :show="showInvoicePdf"
    @close="showInvoicePdf = false"
    :invoice="selectedInvoice"
    @update:invoice="selectedInvoice = $event"
  />
  <app-modal-invoice-view
    :show="showInvoiceView"
    @close="showInvoiceView = false"
    @refresh="refresh"
  />
  <app-modal
    :show="showDeleteConfirmation"
    @close="showDeleteConfirmation = false"
  >
    <template v-slot:title>{{ $t("invoice.action.delete_invoice") }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("invoice.action.delete_invoice_desc") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @onClick="deleteInvoice"
        :showf70Icon="false"
        :primary="false"
        type="button"
        class="
          text-white
          outline-none
          sm:ml-3 sm:w-auto
          bg-error
          hover:bg-error
        "
      >
        {{ $t("general.delete") }}
      </app-button>
    </template>
  </app-modal>
  <app-table
    :apiResponse="apiPaginationResponse.data"
    :titleTop="true"
    :titleTopText="$t('invoice.invoice_title')"
    :loading="apiPaginationLoading"
    :showNumber="false"
    @params-changed="paramsChanged"
    @tab-changed="tabChanged"
    @empty-button-clicked="goToCreateNewInvoice"
    @on-item-click="displayInvoiceView"
    :emptyTitle="$t('invoice.no_invoice_title')"
    :emptyDescription="$t('invoice.no_invoice_description')"
    :emptyButton="$t('invoice.create_new_invoice')"
    :tabs="tabs"
    :filters="[
      {
        key: 'filter[customer_name]',
        type: 'text',
        placeholder: $t('customer.customer_name'),
        value: '',
      },
      {
        key: 'filter[amount]',
        type: 'number',
        placeholder: $t('invoice.table_header.due_amount'),
        value: '',
      },
      {
        key: 'filter[customer_email]',
        type: 'text',
        placeholder: $t('customer.customer_email'),
        value: '',
      },
      {
        key: 'filter[product_name]',
        type: 'text',
        placeholder: $t('invoice.detail.product_name'),
        value: '',
      },
      {
        key: 'filter[invoice_no]',
        type: 'text',
        placeholder: $t('invoice.table_header.no'),
        value: '',
      },
      {
        key: 'filter[status]',
        type: 'select',
        placeholder: $t('invoice.table_header.status'),
        value: {},
        items: [
          {
            id: 'created',
            name: 'Created',
          },
          {
            id: 'open',
            name: 'Open',
          },
          {
            id: 'void',
            name: 'Void',
          },
          {
            id: 'overdue',
            name: 'Overdue',
          },
        ],
      },
      {
        key: 'filter[created_at_between]',
        type: 'date',
        value: '',
      },
      {
        key: 'filter[due_date_between]',
        type: 'date',
        value: '',
        placeholder: $t('invoice.filter.due_date_from_to'),
      },
    ]"
  >
    <template v-slot:header>
      <th class="font-bold">{{ $t("invoice.table_header.status") }}</th>
      <th class="font-bold">{{ $t("invoice.table_header.no") }}</th>
      <th class="font-bold">
        {{ $t("invoice.table_header.customer_name") }}
      </th>
      <th class="font-bold">
        {{ $t("invoice.table_header.customer_email") }}
      </th>
      <th class="font-bold">{{ $t("invoice.table_header.due_amount") }}</th>
      <th class="font-bold">{{ $t("invoice.table_header.due_date") }}</th>
      <th class="font-bold text-center">{{ $t("general.actions") }}</th>
    </template>
    <template v-slot:body="data">
      <td>
        <span
          class="px-3 py-2 rounded-md"
          :class="{
            'bg-gray-200 text-gray-600':
              data.model.status === invoiceStatus.CREATED,
            'bg-blue-100 text-blue-900':
              data.model.status === invoiceStatus.OPEN,
            'bg-red-400 text-white':
              data.model.status === invoiceStatus.OVERDUE,
            'bg-green-400 text-white': data.model.status === invoiceStatus.PAID,
            'bg-blue-300 text-blue-600':
              data.model.status === invoiceStatus.VOID,
          }"
        >
          {{ $t(`invoice.detail.status.${data.model.status}_short`) }}
        </span>
      </td>
      <td>{{ data.model.invoice_no }}</td>
      <td>{{ data.model.customer.name }}</td>
      <td>{{ data.model.customer.email }}</td>
      <td>
        <p class="font-medium">
          {{
            $formats.currency(
              data.model.currency,
              data.model.total_amount ?? 0.0
            )
          }}
        </p>
      </td>
      <td>
        {{
          data.model.payment_term_date
            ? this.$moment(data.model.payment_term_date, "YYYY-MM-DD").format(
                "DD MMMM YYYY"
              )
            : "-"
        }}
      </td>
      <td class="stopItemClick">
        <div class="flex justify-start space-x-2 text-gray-400">
          <app-button-outline
            v-if="data.model.status === invoiceStatus.CREATED"
            class="w-full px-2 py-1"
            @click.stop="displayEditInvoiceForm(data.model)"
          >
            {{ $t("invoice.action.edit_invoice") }}
          </app-button-outline>
          <app-button-outline
            v-else
            class="w-full px-2 py-1"
            @click.stop="displayInvoicePdf(data.model)"
          >
            {{ $t("invoice.action.view_invoice") }}
          </app-button-outline>
          <app-button-dropdown :showArrow="true">
            <div class="py-2">
              <app-icon
                name="DotsVerticalIcon"
                :class="['h-5 w-5 text-gray-500 transition transform']"
              >
              </app-icon>
            </div>
            <template #menu-items>
              <app-button-dropdown-item
                v-for="item in getActionItems(data.model.status)"
                :key="item.key"
                :disabled="item.disable"
                :show="item.show"
                class="p-3"
                @click.stop="handleActionItemClick(item, data.model)"
              >
                {{ item.title }}
              </app-button-dropdown-item>
            </template>
          </app-button-dropdown>
        </div>
      </td>
    </template>
    <template v-slot:button>
      <div class="flex flex-row space-x-2">
        <app-button
          width="w-1/8"
          :showf70Icon="false"
          @click="goToCreateNewInvoice"
        >
          <template v-slot:icon>
            <app-icon-outline name="PlusIcon" class="w-5 h-5 mr-2 text-white" />
          </template>
          {{ $t("invoice.create.create_invoice_button") }}
        </app-button>
      </div>
    </template>
  </app-table>
</template>

<script>
import INVOICE_STATUS from "@/utils/const/invoice_status";

export default {
  data() {
    return {
      queryParams: "",
      showInvoicePdf: false,
      showInvoiceView: false,
      showDeleteConfirmation: false,
      selectedInvoice: {},
      action_items: [],
      invoiceStatus: INVOICE_STATUS,
      tabs: [],
      current_tab: null,
    };
  },

  mounted() {
    this.fetchListInvoices();

    this.tabs = [
      { key: "all", title: this.$t("invoice.table_header.tab_all") },
      {
        key: INVOICE_STATUS.PAID,
        title: this.$t("invoice.table_header.tab_paid"),
      },
      {
        key: INVOICE_STATUS.OPEN,
        title: this.$t("invoice.table_header.tab_outstanding"),
      },
      {
        key: INVOICE_STATUS.OVERDUE,
        title: this.$t("invoice.table_header.tab_overdue"),
      },
    ];
  },

  computed: {
    apiPaginationResponse() {
      return this.$store.getters["invoiceStore/apiPaginationResponse"];
    },
    apiPaginationLoading() {
      return this.$store.getters["invoiceStore/apiPaginationLoading"];
    },
  },

  methods: {
    paramsChanged(filters, queryParams) {
      this.queryParams = queryParams;
      this.fetchListInvoices();
    },
    async deleteInvoice() {
      const result = await this.$store.dispatch(
        "invoiceStore/deleteInvoice",
        this.selectedInvoice
      );
      if (!this.$lodash.isNil(result)) {
        this.refresh();
      }
      this.showDeleteConfirmation = false;
    },
    displayDeleteConfirmation(invoice) {
      this.selectedInvoice = invoice;
      this.showDeleteConfirmation = true;
    },
    goToCreateNewInvoice() {
      this.$router.push("/invoice/create");
    },
    fetchListInvoices() {
      this.getTabStatusTab();
      this.$store.dispatch("invoiceStore/retrieveInvoices", this.queryParams);
    },
    refresh() {
      this.fetchListInvoices();
    },
    async selectInvoice(data) {
      const result = await this.$store.dispatch(
        "invoiceStore/getInvoiceById",
        data._id
      );
      this.$store.dispatch(
        "customerStore/updateLocalCustomer",
        result.data.customer
      );
      this.$store.dispatch("invoiceStore/getInvoiceHistory", data._id);
      this.selectedInvoice = result.data;
    },
    async displayInvoicePdf(data) {
      await this.selectInvoice(data);
      this.showInvoicePdf = true;
    },
    async displayInvoiceView(data) {
      await this.selectInvoice(data);
      this.showInvoiceView = true;
    },
    displayEditInvoiceForm(data) {
      return this.$router.push(`/invoice/edit/${data._id}`);
    },
    getActionItems(status) {
      return [
        {
          key: "duplicate",
          title: this.$t("invoice.action.duplicate_invoice"),
          show:
            status !== INVOICE_STATUS.OVERDUE && status !== INVOICE_STATUS.VOID,
        },
        {
          key: "download",
          title: this.$t("invoice.action.download_as_pdf"),
          show: status !== INVOICE_STATUS.CREATED,
        },
        {
          key: "delete",
          title: this.$t("invoice.action.delete_invoice"),
          show: status === INVOICE_STATUS.CREATED,
        },
      ];
    },
    handleActionItemClick(menu, data) {
      if (menu.key === "duplicate") {
        return this.$router.push(`/invoice/duplicate/${data._id}`);
      }

      if (menu.key === "download") {
        let payload = {
          businessId: data.business_id,
          invoiceId: data._id,
        };
        this.$store.dispatch("invoiceStore/downloadInvoicePdf", payload);
        return;
      }

      if (menu.key === "delete") {
        return this.displayDeleteConfirmation(data);
      }
    },
    tabChanged(tabName) {
      this.currentTab = tabName;
    },
    getTabStatusTab() {
      var status_tab = null;
      let currentTab = this.current_tab ?? this.$route.query.tab;

      if (currentTab == undefined || currentTab == null) {
        currentTab = "";
      }

      status_tab = currentTab;

      if (currentTab == "all" || currentTab == undefined) {
        return;
      }

      this.queryParams =
        this.queryParams == ""
          ? "?filter[status_tab]=" + status_tab
          : this.queryParams + "&filter[status_tab]=" + status_tab;
    },
  },
};
</script>
